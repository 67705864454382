import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteLoaderData } from 'react-router';
import { $routeId } from 'safe-routes';

import type { Route } from '~/types/_auth';

/**
 * Returns the currency object from the configuration
 */
export const useCurrency = () => {
  const loaderData = useRouteLoaderData<Route.ComponentProps['loaderData']>($routeId('routes/_auth'));
  if (!loaderData) {
    throw new Error('Currency not found');
  }

  return loaderData?.currency;
};

/**
 * Returns the currency string based on the current language.
 */
export const useCurrencyString = () => {
  const currency = useCurrency();
  const { i18n } = useTranslation();

  return useMemo(
    () => new Intl.DisplayNames([i18n.language], { type: 'currency' }).of(currency.symbol ?? 'EUR'),
    [currency.symbol, i18n.language]
  );
};
